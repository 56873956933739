<template>
	<div class="bg-gray-100 rounded overflow">
		<div class="flex justify-between align-items-center p-2">
			<button @click="clearDataField('all')" class="bg-white text-xs rounded border shadow-sm p-1">{{
					$t('clear')
				}}
			</button>
			<small class="text-xs">{{ $t('filter') }}</small>
			<button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">
				{{ $t('done') }}
			</button>
		</div>
		<div class="flex flex-col">
			<div :class="{'tab-two-class': tab === 2}">
				<div v-if="tab === 1">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterData.has_images"
								   value="product-image-filter"/>
							<span class="ml-2 cursor-pointer text-xs">{{ $t('productWithImage') }} </span>
						</label>
					</div>
					<div v-if="filterData.has_images === true" class="p-2">
						<div class="form-group">
							<select v-model="filterValue.has_images" class="form-select w-full">
								<option selected disabled>---</option>
								<option value='True'>{{ $t('yes') }}</option>
								<option value='False'>{{ $t('no') }}</option>
							</select>
						</div>
					</div>
				</div>

				<div v-if="tab === 1">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterData.is_relevant"
								   value="relevant"/>
							<span class="ml-2 cursor-pointer text-xs">{{ $t('relevantArticle') }}</span>
						</label>
					</div>
					<div v-if="filterData.is_relevant === true" class="p-2">
						<div class="form-group">
							<select v-model="filterValue.is_relevant" class="form-select w-full">
								<option selected disabled>---</option>
								<option value='true'>{{ $t('yes') }}</option>
								<option value='False'>{{ $t('no') }}</option>
							</select>
						</div>
					</div>
				</div>
				<div v-if="tab === 1">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer"
								   v-model="filterData.customer_specific"
								   value="customer_specific"/>
							<span class="ml-2 cursor-pointer text-xs">{{ $t('CustomerSpecific') }}</span>
						</label>
					</div>
					<div v-if="filterData.customer_specific === true" class="p-2">
						<div class="form-group">
							<select v-model="filterValue.customer_specific" class="form-select w-full">
								<option selected disabled>---</option>
								<option value='True'>{{ $t('yes') }}</option>
								<option value='False'>{{ $t('no') }}</option>
							</select>
						</div>
					</div>
				</div>

				<div v-if="tab === 2">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterDateTab2.sales"
								   value="sales"/>
							<span class="ml-2 cursor-pointer">{{ $t('salesPrice') }}

							</span>
						</label>
					</div>
					<div v-if="filterDateTab2.sales === true || accordionIcon === true" class="p-2">
						<div class="form-group">
							<select v-model="salesObject.range"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<!-- <option selected disabled>---</option> -->
								<!-- <option value='equal'>{{ $t('isEqualTo') }}</option> -->
								<!-- <option value='greater'>{{ $t('isGreaterThan') }}</option> -->
								<option value='between' selected>{{ $t('isBetween') }}</option>
								<!-- <option value='less'>{{ $t('isLessThan') }}</option> -->
							</select>
						</div>
						<div class="form-group">
							<div class="flex justify-between" v-if="salesObject.range === 'between'">
								<input type="number" min="0" v-model="salesObject.start"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<input type="number" min="0" v-model="salesObject.end"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
							<div v-else>
								<input type="number" min="0" v-model="salesObject.value"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
				</div>

				<div v-if="tab === 2">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterDateTab2.cost"
								   value="cost"/>
							<span class="ml-2 cursor-pointer">{{ $t('costPrice') }}</span>
						</label>
					</div>
					<div v-if="filterDateTab2.cost === true" class="p-2">
						<div class="form-group">
							<select v-model="costPriceObj.range"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<option selected disabled>---</option>
								<!-- <option value='equal'>{{ $t('isEqualTo') }}</option> -->
								<!-- <option value='greater'>{{ $t('isGreaterThan') }}</option> -->
								<option value='between'>{{ $t('isBetween') }}</option>
								<!-- <option value='less'>{{ $t('isLessThan') }}</option> -->
							</select>
						</div>
						<div class="form-group">
							<div class="flex justify-between" v-if="costPriceObj.range === 'between'">
								<input type="number" min="0" v-model="costPriceObj.start"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<input type="number" min="0" v-model="costPriceObj.end"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
							<div v-else>
								<input type="number" min="0" v-model="costPriceObj.value"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
				</div>

				<div v-if="tab === 2">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterDateTab2.margin"
								   value="margin"/>
							<span class="ml-2 cursor-pointer">{{ $t('margin') }}</span>
						</label>
					</div>
					<div v-if="filterDateTab2.margin === true" class="p-2">
						<div class="form-group">
							<select v-model="marginObject.range"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<option selected disabled>---</option>
								<option value='equal'>{{ $t('isEqualTo') }}</option>
								<option value='greater'>{{ $t('isGreaterThan') }}</option>
								<option value='between'>{{ $t('isBetween') }}</option>
								<option value='less'>{{ $t('isLessThan') }}</option>
							</select>
						</div>
						<div class="form-group">
							<div class="flex justify-between" v-if="marginObject.range === 'between'">
								<input type="number" min="0" v-model="marginObject.start"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<input type="number" min="0" v-model="marginObject.end"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
							<div v-else>
								<input type="number" min="0" v-model="marginObject.value"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
				</div>

				<div v-if="tab === 2">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterDateTab2.vat_rate"
								   value="vat"/>
							<span class="ml-2 cursor-pointer text-xs whitespace-nowrap">{{ $t('vatRate') }}</span>
						</label>
					</div>
					<div v-if="filterDateTab2.vat_rate === true" class="p-2">
						<div class="form-group">
							<input type="number" min="0" v-model="vatRate.value"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
						</div>
					</div>
				</div>

				<div v-if="tab === 2">
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="checkbox" class="form-radio cursor-pointer" v-model="filterDateTab2.quantity"
								   value="quantity"/>
							<span class="ml-2 cursor-pointer">{{ $t('quantity') }}</span>
						</label>
					</div>
					<div v-if="filterDateTab2.quantity === true" class="p-2">
						<div class="form-group">
							<select v-model="quantityObj.range"
									class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<option selected disabled>---</option>
								<!-- <option value='equal'>{{ $t('isEqualTo') }}</option> -->
								<!-- <option value='greater'>{{ $t('isGreaterThan') }}</option> -->
								<option value='between'>{{ $t('isBetween') }}</option>
								<!-- <option value='less'>{{ $t('isLessThan') }}</option> -->
							</select>
						</div>
						<div class="form-group">
							<div class="flex justify-between" v-if="quantityObj.range === 'between'">
								<input type="number" min="0" v-model="quantityObj.start"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
								<input type="number" min="0" v-model="quantityObj.end"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
							<div v-else>
								<input type="number" min="0" v-model="quantityObj.value"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: 'FilterComponent',
	props: {
		data: {
			type: String,
			required: false
		},
		tab: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			filter: '',
			value1: '',
			marginObject: {
				range: 'between',
				value: 0,
				start: 0,
				end: 0
			},
			salesObject: {
				range: 'between',
				value: 0,
				start: 0,
				end: 0
			},
			quantityObj: {
				range: 'between',
				value: 0,
				start: 0,
				end: 0
			},
			costPriceObj: {
				range: 'between',
				value: 0,
				start: 0,
				end: 0
			},
			vatRate: {
				value: '',
			},
			source: {
				name: ''
			},
			relevant: {
				name: ''
			},
			productWithImage: {
				name: ''
			},
			customerSpecific: {
				name: ''
			},
			filterValue: {
				customer_specific: '',
				is_relevant: '',
				has_images: ''
			},
			filterData: {
				customer_specific: false,
				is_relevant: false,
				has_images: false
			},
			filterDateTab2: {
				sales: false,
				cost: false,
				margin: false,
				vat_rate: false,
				quantity: false,
			},
			accordionIcon: {
				sales: false,
				cost: false,
				margin: false,
				vat_rate: false,
				quantity: false,
			},
			filterValueTab2: {
				sales: ''
			}
		}
	},
	computed: {
		...mapGetters({
			GET_ARTICLE_ACTIVE_FILTER: 'portal/GET_ARTICLE_ACTIVE_FILTER',
			GET_ARTICLE_PRODUCT_PRICE_FILTER: 'portal/GET_ARTICLE_PRODUCT_PRICE_FILTER',
		})
	},
	watch: {
		filter: function (newVal, _) {
			this.filter = ''
			this.filter = newVal
		}
	},
	methods: {
		filterDataNow() {


			// if (this.filter === 'sales') {
			// 	if (this.salesObject.range && (parseFloat(this.salesObject.start) || parseFloat(this.salesObject.end))) {
			// 		this.$emit('filterSalesPrice', this.salesObject)
			// 	}
			// }
			//
			// if (this.filter === 'cost') {
			// 	if (this.costPriceObj.range && (parseFloat(this.costPriceObj.start) || parseFloat(this.costPriceObj.end))) {
			// 		this.$emit('filterCostPrice', this.costPriceObj)
			// 	}
			// }
			//
			// if (this.filter === 'quantity') {
			// 	if (this.quantityObj.range && (parseFloat(this.quantityObj.start) || parseFloat(this.quantityObj.end))) {
			// 		this.$emit('filterQuantity', this.quantityObj)
			// 	}
			// }
			//
			// if (this.filter === 'margin') {
			// 	// console.log(this.marginObject)
			// 	if (this.marginObject.range && (parseInt(this.marginObject.value) || parseInt(this.marginObject.start) || parseInt(this.marginObject.end))) {
			// 		this.$store.commit('portal/ADVANCE_FILTER_ARTICLE_MARGIN', this.marginObject)
			// 	}
			// }
			//
			// if (this.filter === 'source') {
			// 	if (this.source && this.source.name) {
			// 		window.Bus.$emit('load-request')
			// 		this.$store.dispatch('portal/advanceFilterArticle', {query: `accounting_type=${this.source.name}`})
			// 			.then(_ => {
			// 				window.Bus.$emit('finish-request')
			// 			})
			// 			.catch(_ => {
			// 				window.Bus.$emit('finish-request')
			// 			})
			// 	}
			// }
			//
			// if (this.filter === 'vat') {
			// 	if (this.vatRate && this.vatRate.value) {
			// 		// this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_TYPE', this.supplier)
			// 		this.$emit('filterVatRate', this.vatRate)
			// 	}
			// }
			if (this.tab === 1) {
				for (const [key, value] of Object.entries(this.filterData)) {
					if (value === true) {
						if (this.filterValue[key] !== '') {
							this.$store.commit('portal/SET_ARTICLE_ACTIVE_FILTER', {
								mode: key,
								value: this.filterValue[key]
							})

						}
					} else {
						this.$store.commit('portal/REMOVE_ARTICLE_ACTIVE_FILTER', {mode: key})
					}
				}
				window.Bus.$emit('filter-article-tab-1')
			}
			if (this.tab === 2) {
				let payload = {
					sales: this.salesObject,
					cost: this.costPriceObj,
					margin: this.marginObject,
					vat_rate: this.vatRate.value,
					quantity: this.quantityObj,
				}
				for (const [key, value] of Object.entries(this.filterDateTab2)) {
					if (value === true) {
						if (this.filterValue[key] !== '') {
							this.$store.commit('portal/SET_ARTICLE_PRODUCT_PRICE_FILTER', {
								mode: key,
								value: payload[key]
							})
						}
					}
				}
				window.Bus.$emit('filter-article-tab-2')
			}
			// this.clearDataField()
			this.$emit('hideAdvanceFilter')
		},
		clearDataField(d = null) {
			this.filter = ''
			Object.keys(this.salesObject).forEach(element => {
				this.salesObject[element] = ''
			})
			Object.keys(this.costPriceObj).forEach(element => {
				this.costPriceObj[element] = ''
			})
			Object.keys(this.quantityObj).forEach(element => {
				this.quantityObj[element] = ''
			})
			Object.keys(this.marginObject).forEach(element => {
				this.marginObject[element] = ''
			})
			Object.keys(this.vatRate).forEach(element => {
				this.vatRate[element] = ''
			})
			Object.keys(this.source).forEach(element => {
				this.source[element] = ''
			})
			Object.keys(this.relevant).forEach(element => {
				this.relevant[element] = ''
			})
			Object.keys(this.productWithImage).forEach(element => {
				this.productWithImage[element] = ''
			})
			Object.keys(this.customerSpecific).forEach(element => {
				this.customerSpecific[element] = ''
			})
			if (d !== null) {
				this.$store.commit('portal/FILTER_ALL')
				window.Bus.$emit('reset-articles')
			}
			if (this.tab === 1) {
				for (const [key, value] of Object.entries(this.filterData)) {
					this.$store.commit('portal/REMOVE_ARTICLE_ACTIVE_FILTER', {mode: key})
				}
				window.Bus.$emit('filter-article-tab-1')
			}
			if (this.tab === 2) {
				this.filterDateTab2 = {
					sales: false,
					cost: false,
					margin: false,
					vat_rate: false,
					quantity: false,
				}
				for (const [key, value] of Object.entries(this.filterDateTab2)) {
					this.$store.commit('portal/REMOVE_ARTICLE_PRODUCT_PRICE_FILTER', {mode: key})
				}
				window.Bus.$emit('filter-article-tab-1')
			}

			window.Bus.$emit('clear-filter-data')
		}
	},
	mounted() {
		if (this.tab === 1) {
			this.GET_ARTICLE_ACTIVE_FILTER.map(item => {
				if (item.mode === 'customer_specific') {
					this.filterData.customer_specific = true
					this.filterValue.customer_specific = item.value
				} else if (item.mode === 'is_relevant') {
					this.filterData.is_relevant = true
					this.filterValue.is_relevant = item.value
				} else if (item.mode === 'has_images') {
					this.filterData.has_images = true
					this.filterValue.has_images = item.value
				}
			})
		} else if (this.tab === 2) {
			this.GET_ARTICLE_PRODUCT_PRICE_FILTER.map(item => {
				if (item.mode === 'sales') {
					this.filterDateTab2.sales = true
					this.salesObject = item.value
				} else if (item.mode === 'cost') {
					this.filterDateTab2.cost = true
					this.costPriceObj = item.value
				} else if (item.mode === 'margin') {
					this.filterDateTab2.margin = true
					this.costPriceObj = item.value
				} else if (item.mode === 'vat_rate') {
					this.filterDateTab2.vat_rate = true
					this.vatRate.value = item.value
				} else if (item.mode === 'quantity') {
					this.filterDateTab2.quantity = true
					this.quantityObj = item.value
				}

			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.el-date-editor--daterange.el-input__inner {
	width: unset !important;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.filter-icon {
	position: relative;
	text-align: right;
	float: right;
	right: -94px;
}

.tab-two-class {
	height: 188px;
	overflow-y: scroll;
}
</style>
